import React from 'react';
import { PageProps } from 'gatsby';
import { Layout, SEO } from '@core';
import { Article, Author } from '@types';
import { SlugContext, Slugs } from '../../core/layout/SlugContext';
import { AuthorAndTag } from './AuthorAndTag';
import { AuthorHeader } from './AuthorHeader';
import { TagHeader } from './TagHeader';

interface PageContextProps extends Author {
  isAuthor: boolean;
  articles: Article[];
  slugs: Slugs;
}

interface AuthorAndTagDetailsProps extends PageProps {
  pageContext: PageContextProps;
}

const AuthorAndTagDetailsPage = ({ pageContext }: AuthorAndTagDetailsProps) => {
  const { avatar, description, name, title, text, isAuthor, articles } = pageContext;

  return (
    <SlugContext.Provider value={pageContext.slugs}>
      <Layout>
        <SEO title={title} description={description} />
        <AuthorAndTag articles={articles} isAuthor={isAuthor}>
          {isAuthor ? <AuthorHeader name={name} text={text} avatar={avatar} title={title} /> : <TagHeader title={title} description={description} />}
        </AuthorAndTag>
      </Layout>
    </SlugContext.Provider>
  );
};

export default AuthorAndTagDetailsPage;

import React from 'react';
import { Box } from '@themed';
import { SectionWithContent } from '@core';
import { NewsLetterSignup } from '@composite';
import { Article } from '@types';
import { BlogList } from '../common/BlogList';
import { DefaultNewsLetterContent } from '../NewsLetterContent';
import { styles } from './AuthorAndTag.styles';

interface AuthorAndTagProps {
  children: JSX.Element;
  articles: Article[];
  isAuthor?: boolean;
}

export const AuthorAndTag = ({ children, articles, isAuthor = false }: AuthorAndTagProps) => (
  <React.Fragment>
    <SectionWithContent sx={styles.section(isAuthor)}>
      <Box sx={styles.pageWrapper}>{children}</Box>
    </SectionWithContent>
    <SectionWithContent sx={styles.list}>
      <BlogList articles={articles} />
    </SectionWithContent>
    <NewsLetterSignup id='pages.blog.contact'>
      <DefaultNewsLetterContent id='pages.blog.contact' />
    </NewsLetterSignup>
  </React.Fragment>
);
